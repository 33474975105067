.camps-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: var(--font-base);
    color: #9d782a
}

a.btn{
    background-color: #FFFFFF;
    border: 1px solid #9D782A;
    border-radius: 8px;
    box-sizing: border-box;
    color: #9D782A;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 23px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s, transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
    margin-top: 1rem;
}

a.btn:hover{
    background-color: #9D782A;
    color: #FFFFFF;
}

.para{
    font-family: var(--font-alt);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
}

.btn:hover{
    background-color: #9D782A;
    color: #FFFFFF;
}
