.container-with-background {
    /*background-image: url('https://nextui.org/images/card-example-4.jpeg');*/
    background-size: cover;
    background-position: center;
    padding: 3rem 0;
    width: 80%;
    margin: auto;
}

.headtext{
    color: #9d782a;
}