.images:hover{
    transform: scale(1.2) ;
    transition: ease-out 0.6s;
}

a.btn {
    background: #0096a0;
    border-radius: 4px;
    box-shadow: 0 2px 0 0 rgba(0,0,0,0.25);
    color: white;
    display: inline-block;
    padding: 6px 30px 8px;
    position: relative;
    text-decoration: none;
    transition: all 0.1s 0s ease-out;
}

.no-touch a.btn:hover {
    background: #0096a0;
    box-shadow: 0px 8px 2px 0 rgba(0, 0, 0, 0.075);
    transform: translateY(-2px);
    transition: all 0.25s 0s ease-out;
}

.no-touch a.btn:active,
a.btn:active {
    background: #0096a0;
    box-shadow: 0 1px 0 0 rgba(255,255,255,0.25);
    transform: translate3d(0,1px,0);
    transition: all 0.025s 0s ease-out;
}

div.cards {
    margin: 80px auto;
    max-width: 960px;
    text-align: center;
}

div.card {
    background: white;
    display: inline-block;
    margin: 8px;
    max-width: 300px;
    /*perspective: 1000;*/
    position: relative;
    text-align: left;
    transition: all 0.3s 0s ease-in;
    width: 300px;
    z-index: 1;
}
div.card img {
    /*max-width: 300px;*/
    object-fit: cover;
}

.card__image-holder {
    background: rgba(0,0,0,0.1);
    /*height: 0;*/
    /*padding-bottom: 75%;*/
}

div.card-title {
    /*background: white;*/
    padding: 6px 15px 10px;
    position: relative;
    z-index: 0;
}

a.toggle-info {
    border-radius: 32px;
    height: 32px;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 32px;
}

span {
    background: white;
    display: block;
    height: 2px;
    position: absolute;
    top: 16px;
    transition: all 0.15s 0s ease-out;
    width: 12px;
}

span.left {
    right: 14px;
    transform: rotate(45deg);
}
span.right {
    left: 14px;
    transform: rotate(-45deg);
}


h2 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    padding: 0;
}

small {
    display: block;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.025em;
}
div.card-description {
    font-family: var(--font-alt);
    padding: 0 15px 10px;
    position: relative;
    font-size: 14px;
}

div.card-actions {
    box-shadow: 0 2px 0px 0 rgba(0,0,0,0.075);
    padding: 10px 15px 20px;
    text-align: center;
}

div.card-flap {
    background: darken(white,15);
    position: absolute;
    width: 100%;
    transform-origin: top;
    transform: rotateX(-90deg);
}
div.flap1 {
    transition: all 0.3s 0.3s ease-out;
    z-index: -1;
}
div.flap2 {
    transition: all 0.3s 0s ease-out;
    z-index: -2;
}



div.cards.showing div.card {
    cursor: pointer;
    opacity: 0.6;
    transform: scale(0.88);
}

.no-touch  div.cards.showing div.card:hover {
    opacity: 0.94;
    transform: scale(0.92);
}

div.card.show {
    opacity: 1 !important;
    transform: scale(1) !important;
}
div.card-title a.toggle-info {
    background: #ff6666;
}
div.card-title a.toggle-info span {
    top: 15px;
}
div.card-title a.toggle-info span.left {
    right: 10px;
}
div.card-title a.toggle-info span.right {
    left: 10px;
}

div.card-flap {
    background: white;
    transform: rotateX(0deg);
}
div.flap1 {
    transition: all 0.3s 0s ease-out;
}
div.flap2 {
    transition: all 0.3s 0.2s ease-out;
}

.cardhover:hover{
        transform: scale(1.05);
        box-shadow: 4px 4px 12px #d7d7d7;
}



