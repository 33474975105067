.app__laurels_awards {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.headtext__cormorant{
    color: #9d782a;
}

.app__laurels_awards-card {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
}

@media screen and (min-width: 1900px) {
    .app__laurels_awards-card {
        min-width: 390px;
    }
}

.app__laurels_awards-card img {
    width: 50px;
    height: 50px;
}

.app__laurels_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
.awards_image{
    -webkit-box-shadow: inset 0px 0px 24px -4px rgba(0,0,0,0.79);
    -moz-box-shadow: inset 0px 0px 24px -4px rgba(0,0,0,0.79);
    box-shadow: inset 0px 0px 24px -4px rgba(0,0,0,0.79);
    background-color: #9d782a;
    color: white;
    object-fit: cover;
}

@media screen and (max-width: 450px) {
    .app__laurels_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}

.textd{
    /*text-align: center;*/
    margin: auto;
    color: black;
    font-family: var(--font-alt);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
    padding: 10px 0;
}
