.head{
    font-weight: 700;
    color: white;
}

.app__header-h1 {
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 75px;
    font-size: 60px;
}


.app__header-img img {
    width: 80%;
}

.video{
    width: 100%;
    object-fit: fill;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-blend-mode: overlay;
    z-index: -1;
}
.videostyles{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 90px;
        line-height: 120px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 55px;
        line-height: 70px;
    }
}

@media only screen and (max-width: 992px) {
    .app__header-h1{
        font-size: 50px;
        line-height: 60px;
    }
    .nextbutton{
        display: none;
    }
    .prevbutton{
        display: none;
    }
}


.health{
    font-size: 1.7rem;
}
.des{
    font-size: 1.4rem;
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 45px;
        line-height: 50px;
    }
    .health{
        font-size: 1rem;
    }
    .des{
        font-size: 1rem;
    }
}

.carouselimg{
    width: 100vw;
    height: 100vh;
}

.carousel-text{
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    z-index: 1;
    left: 10%;
    width: 80%;
}

.carouselimg{
    opacity: 0.8;
}
