.heading{
    border-bottom: 1px solid #333;
}
.room{
    color: var(--color-golden);
    letter-spacing: .04em;
    font-family: var(--font-alt);
    font-weight: 400;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
}
.card-title {
    padding-top: 20px;
    font-family: var(--font-base);
    color: #9D782A;
    font-size: 40px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    text-align: center;
}
.card-description{
    margin: auto;
    color: black;
    font-family: var(--font-alt);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 28px;
    font-size: 16px;
    padding: 10px 0;
}
.read-more-btn{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    color: #74613c;
    font-size: 30px;
    padding-top: 10px;
}
.read{
   background-color: #FFFFFF;
   border: 1px solid #9D782A;
   border-radius: 8px;
   box-sizing: border-box;
   color: #9D782A;
   cursor: pointer;
   display: inline-block;
   font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
   font-size: 16px;
   font-weight: 600;
   line-height: 20px;
   margin: 0;
   outline: none;
   padding: 13px 23px;
   position: relative;
   text-align: center;
   text-decoration: none;
   touch-action: manipulation;
   transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
   user-select: none;
   -webkit-user-select: none;
   width: auto;
}

.read:hover{
    background-color: #9D782A;
    color: white;
}

.read:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
}

.read:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
}

.read:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
}

.room{
    color:#9D782A
}

  