.package{
    width: 80%;
    margin: 0 auto;
    padding: 3rem 0 0;
}
.headtext {
    font-family: var(--font-base);
    color: #9d782a;
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    margin: auto;
    padding: 30px 0;
    text-align: center;
}

.row-cols-1 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-md-3 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.row-cols-md-3 > * {
    flex: 0 0 calc(100% / 3);
    max-width: calc(100% / 3);
}

.g-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    row-gap: var(--bs-gutter-y);
    column-gap: var(--bs-gutter-x);
}

.h-100 {
    height: 100%;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-text {
    margin-top: 0;
    margin-bottom: 1rem;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.text-muted {
    color: #6c757d;
}


